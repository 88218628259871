exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-despre-noi-js": () => import("./../../../src/pages/Despre_noi.js" /* webpackChunkName: "component---src-pages-despre-noi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-servicii-deratizare-js": () => import("./../../../src/pages/Servicii/Deratizare.js" /* webpackChunkName: "component---src-pages-servicii-deratizare-js" */),
  "component---src-pages-servicii-dezinfectie-js": () => import("./../../../src/pages/Servicii/Dezinfectie.js" /* webpackChunkName: "component---src-pages-servicii-dezinfectie-js" */),
  "component---src-pages-servicii-dezinsectie-js": () => import("./../../../src/pages/Servicii/Dezinsectie.js" /* webpackChunkName: "component---src-pages-servicii-dezinsectie-js" */),
  "component---src-pages-servicii-index-js": () => import("./../../../src/pages/Servicii/index.js" /* webpackChunkName: "component---src-pages-servicii-index-js" */),
  "component---src-pages-termeni-jsx": () => import("./../../../src/pages/Termeni.jsx" /* webpackChunkName: "component---src-pages-termeni-jsx" */)
}

